import { createContext, useContext } from 'react'

import { AxiosError } from 'axios'

export interface MessageDialogOptions {
  variant: 'info' | 'success' | 'warning' | 'error'
  title: string
  message: string
}

export interface MessageToastOptions {
  variant: 'info' | 'success' | 'warning' | 'error'
  message: string
}

export interface AppContextProps {
  isLoading: boolean
  showLoading: () => void
  hideLoading: () => void
  showMessageDialog: (options: MessageDialogOptions) => void
  showErrorDialog: (error: unknown | Error | AxiosError) => void
  showMessageToast: (options: MessageToastOptions) => void
  showErrorToast: (error: unknown | Error | AxiosError) => void
}

export const AppContext = createContext<AppContextProps | undefined>(undefined)

export function useApp() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('Missing AppProvider')
  }

  return context
}
