import { constants } from ".";

const urlHelpers = {
  getAbsoluteURL(url) {
    if (url.startsWith("http")) {
      return url;
    }
    if (!url.startsWith("/")) {
      url = `/${url}`;
    }
    return `${constants.HOST_URL}${url}`;
  },
};

export default urlHelpers;
