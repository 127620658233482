// ACTION TYPES
export const TYPES = {
  SET_PAYROLL: "SET_PAYROLL",
  SET_CHECKS: "SET_CHECKS",
  SET_DATASOURCE: "SET_DATASOURCE",
  SET_LIST: "SET_LIST",
  SET_HAS_SCROLLBAR: "SET_HAS_SCROLLBAR",
  SET_INPUT_FORMAT: "SET_INPUT_FORMAT",
  SET_CHECKS_FILTER: "SET_CHECKS_FILTER",
  RESET_PAYROLLS: "RESET_PAYROLLS",
  UPDATE_PAYROLL_STATUS: "UPDATE_PAYROLL_STATUS",
  UPDATE_PAYROLL_CHECKS_STATUS: "UPDATE_PAYROLL_CHECKS_STATUS",
  SET_DISPLAY_OPTIONS: "SET_DISPLAY_OPTIONS",
  SET_EMPLOYEE_JOBS: "SET_EMPLOYEE_JOBS",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_FOCUS_VIEW_CODE: "SET_FOCUS_VIEW_CODE",
  SET_FOCUS_VIEW_LIST: "SET_FOCUS_VIEW_LIST",
  SET_PAYROLL_SUMMARY: "SET_PAYROLL_SUMMARY",
  SET_PAYROLL_IMPORT_BATCHES: "SET_PAYROLL_IMPORT_BATCHES",
  SET_SELECTED_CHECKS: "SET_SELECTED_CHECKS",
  CLEAR_SELECTED_CHECKS: "CLEAR_SELECTED_CHECKS",
  SET_SHOW_PAYROLL_DATA_LOADING: "SET_SHOW_PAYROLL_DATA_LOADING",
  SET_DISABLE_CHECKLINE_UPDATES: "SET_DISABLE_CHECKLINE_UPDATES",
  SET_PAYROLL_CHECKS_SETTINGS: "SET_PAYROLL_CHECKS_SETTINGS",
  SET_CHECKS_COLUMN_SETTINGS: "SET_CHECKS_COLUMN_SETTINGS",
  SET_ASSISTANT_MODE: "SET_ASSISTANT_MODE",
  SET_TOP_NAV_VISIBILITY: "SET_TOP_NAV_VISIBILITY",
};

// ACTION CREATORS
export const setPayroll = (payroll) => ({
  type: TYPES.SET_PAYROLL,
  payload: { payroll },
});

export const setChecks = (checks) => ({
  type: TYPES.SET_CHECKS,
  payload: { checks },
});

export const setDataSource = (dataSource) => ({
  type: TYPES.SET_DATASOURCE,
  payload: { dataSource },
});

export const setList = (list) => ({
  type: TYPES.SET_LIST,
  payload: { list },
});

export const setHasScrollbar = (hasScrollbar) => ({
  type: TYPES.SET_HAS_SCROLLBAR,
  payload: { hasScrollbar },
});

export const setInputFormat = (inputFormat) => ({
  type: TYPES.SET_INPUT_FORMAT,
  payload: { inputFormat },
});

export const setChecksFilter = (checksFilter) => ({
  type: TYPES.SET_CHECKS_FILTER,
  payload: { checksFilter },
});

export const resetPayrolls = () => ({
  type: TYPES.RESET_PAYROLLS,
});

export const updatePayrollStatus = (status) => ({
  type: TYPES.UPDATE_PAYROLL_STATUS,
  payload: { status },
});

export const updatePayrollChecksStatus = (status) => ({
  type: TYPES.UPDATE_PAYROLL_CHECKS_STATUS,
  payload: { status },
});

export const setDisplayOptions = (field, value) => ({
  type: TYPES.SET_DISPLAY_OPTIONS,
  payload: { field, value },
});

export const setEmployeeJobs = (jobs) => ({
  type: TYPES.SET_EMPLOYEE_JOBS,
  payload: { jobs },
});

export const setDebugMode = (debug) => ({
  type: TYPES.SET_DEBUG_MODE,
  payload: { debug },
});

export const setFocusViewCode = (code) => ({
  type: TYPES.SET_FOCUS_VIEW_CODE,
  payload: { code },
});

export const setFocusViewList = (list) => ({
  type: TYPES.SET_FOCUS_VIEW_LIST,
  payload: { list },
});

export const setPayrollSummary = (summary) => ({
  type: TYPES.SET_PAYROLL_SUMMARY,
  payload: { summary },
});

export const setPayrollImportBatches = (importBatches) => ({
  type: TYPES.SET_PAYROLL_IMPORT_BATCHES,
  payload: { importBatches },
});

export const setSelectedChecks = (key, value) => ({
  type: TYPES.SET_SELECTED_CHECKS,
  payload: { key, value },
});

export const clearSelectedChecks = () => ({
  type: TYPES.CLEAR_SELECTED_CHECKS,
});

export const setShowPayrollDataLoading = (loading) => ({
  type: TYPES.SET_SHOW_PAYROLL_DATA_LOADING,
  payload: { loading },
});

export const setDisableChecklineUpdates = (disabled) => ({
  type: TYPES.SET_DISABLE_CHECKLINE_UPDATES,
  payload: { disabled },
});

export const setPayrollChecksSettings = (payrollChecksSettings) => ({
  type: TYPES.SET_PAYROLL_CHECKS_SETTINGS,
  payload: { payrollChecksSettings },
});

export const setChecksColumnSettings = (checksColumnSettings) => ({
  type: TYPES.SET_CHECKS_COLUMN_SETTINGS,
  payload: { checksColumnSettings },
});

export const setAssistantMode = (stickyAssistant) => ({
  type: TYPES.SET_ASSISTANT_MODE,
  payload: { stickyAssistant },
});

export const setTopNavVisibility = (topNavVisible) => ({
  type: TYPES.SET_TOP_NAV_VISIBILITY,
  payload: { topNavVisible },
});

// REDUCER
const initialState = {
  payroll: null,
  payrollStatus: null,
  summary: null,
  checks: [],
  dataSource: null,
  list: null,
  hasScrollbar: false,
  inputFormat: "hours",
  checksFilter: { divDeps: [] },
  displayOptions: { manual: true, voided: true },
  jobs: {},
  debug: false,
  focusViewCode: null,
  focusViewList: [],
  importBatches: [],
  selectedChecks: {},
  showPayrollDataLoading: true,
  disableChecklineUpdates: false,
  stickyAssistant: false,
  topNavVisible: true,
};

export const payrollsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PAYROLL:
      return {
        ...state,
        payroll: action.payload.payroll,
      };
    case TYPES.SET_CHECKS:
      return {
        ...state,
        checks: action.payload.checks,
      };
    case TYPES.SET_DATASOURCE:
      return {
        ...state,
        dataSource: action.payload.dataSource,
      };
    case TYPES.SET_LIST:
      return {
        ...state,
        list: action.payload.list,
      };
    case TYPES.SET_HAS_SCROLLBAR:
      return {
        ...state,
        hasScrollbar: action.payload.hasScrollbar,
      };
    case TYPES.SET_INPUT_FORMAT:
      return {
        ...state,
        inputFormat: action.payload.inputFormat,
      };
    case TYPES.SET_CHECKS_FILTER:
      return {
        ...state,
        checksFilter: action.payload.checksFilter,
      };
    case TYPES.UPDATE_PAYROLL_STATUS:
      return {
        ...state,
        payrollStatus: action.payload.status,
      };
    case TYPES.UPDATE_PAYROLL_CHECKS_STATUS:
      return {
        ...state,
        payroll: {
          ...state.payroll,
          checksStatus: action.payload.status,
        },
      };
    case TYPES.RESET_PAYROLLS:
      return {
        ...state,
        ...initialState,
      };
    case TYPES.SET_DISPLAY_OPTIONS:
      return {
        ...state,
        displayOptions: {
          ...state.displayOptions,
          [action.payload.field]: action.payload.value,
        },
      };
    case TYPES.SET_EMPLOYEE_JOBS:
      return {
        ...state,
        jobs: action.payload.jobs,
      };
    case TYPES.SET_DEBUG_MODE:
      return {
        ...state,
        debug: action.payload.debug,
      };
    case TYPES.SET_FOCUS_VIEW_CODE:
      return {
        ...state,
        focusViewCode: action.payload.code,
      };
    case TYPES.SET_FOCUS_VIEW_LIST:
      return {
        ...state,
        focusViewList: action.payload.list,
      };
    case TYPES.SET_PAYROLL_SUMMARY:
      return {
        ...state,
        summary: action.payload.summary,
      };
    case TYPES.SET_PAYROLL_IMPORT_BATCHES:
      return {
        ...state,
        importBatches: action.payload.importBatches,
      };
    case TYPES.SET_SELECTED_CHECKS:
      return {
        ...state,
        selectedChecks: {
          ...state.selectedChecks,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.CLEAR_SELECTED_CHECKS:
      return {
        ...state,
        selectedChecks: {},
      };
    case TYPES.SET_SHOW_PAYROLL_DATA_LOADING:
      return {
        ...state,
        showPayrollDataLoading: action.payload.loading,
      };
    case TYPES.SET_DISABLE_CHECKLINE_UPDATES:
      return {
        ...state,
        disableChecklineUpdates: action.payload.disabled,
      };
    case TYPES.SET_PAYROLL_CHECKS_SETTINGS:
      return {
        ...state,
        payroll: {
          ...state.payroll,
          payrollChecksSettings: action.payload.payrollChecksSettings,
        },
      };
    case TYPES.SET_CHECKS_COLUMN_SETTINGS:
      return {
        ...state,
        payroll: {
          ...state.payroll,
          payrollChecksSettings: {
            ...state.payroll.payrollChecksSettings,
            checksColumnSettings: action.payload.checksColumnSettings,
          },
        },
      };
    case TYPES.SET_ASSISTANT_MODE:
      return {
        ...state,
        stickyAssistant: action.payload.stickyAssistant,
      };
    case TYPES.SET_TOP_NAV_VISIBILITY:
      return {
        ...state,
        topNavVisible: action.payload.topNavVisible,
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectPayroll = (state) => state.payrolls.payroll;
export const selectPayrollStatus = (state) => state.payrolls.payrollStatus;
export const selectChecks = (state) => state.payrolls.checks;
export const selectDataSource = (state) => state.payrolls.dataSource;
export const selectList = (state) => state.payrolls.list;
export const selectHasScrollbar = (state) => state.payrolls.hasScrollbar;
export const selectInputFormat = (state) => state.payrolls.inputFormat;
export const selectChecksFilter = (state) => state.payrolls.checksFilter;
export const selectDisplayOptions = (state) => state.payrolls.displayOptions;
export const selectJobs = (state) => state.payrolls.jobs;
export const selectDebug = (state) => state.payrolls.debug;
export const selectFocusViewCode = (state) => state.payrolls.focusViewCode;
export const selectFocusViewList = (state) => state.payrolls.focusViewList;
export const selectPayrollSummary = (state) => state.payrolls.summary;
export const selectPayrollImportBatches = (state) => state.payrolls.importBatches;
export const selectSelectedChecks = (state) => state.payrolls.selectedChecks;
export const selectShowPayrollDataLoading = (state) => state.payrolls.showPayrollDataLoading;
export const selectDisableChecklineUpdates = (state) => state.payrolls.disableChecklineUpdates;
export const selectStickyAssistant = (state) => state.payrolls.stickyAssistant;
export const selectIsTopNavVisible = (state) => state.payrolls.topNavVisible;
