import axios from "axios";
import { authService, axiosError, urlHelpers, logErrorsService } from ".";
import { localStorageSyncApi } from "./localStorageSyncApi";
import { sessionStorageSyncApi } from "./sessionStorageSyncApi";
import { store } from "../redux/configureStore";
import { showSnackbar } from "../redux/ducks/snackbarDuck";

const sleep = ms => new Promise(r => setTimeout(r, ms));

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0"
};

axios.interceptors.request.use(request => {
  const state = store?.getState();
  const jwtGuid = state?.app?.profile?.jwtGuid;
  if (jwtGuid) {
    request.headers.jwtGuid = jwtGuid;
  }
  return request;
});

axios.interceptors.request.use(request => {
  const jwtGuid = authService.getJwtGuid();
  if (jwtGuid) {
    request.headers.jwtGuid = jwtGuid;
  }
  return request;
});

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.config?.headers["X-ReCAPTCHAToken_V"] === "V3" && error.response?.status === 506) {
      logErrorsService.warning("google reCAPTCHA V3 failed, will try to switch to V2.");
      let response = "";

      try {
        window.grecaptcha.render("recaptcha", {
          sitekey: "6LeCs6MlAAAAANEnqDuTjqyE954zjz-Za9UqwIKb"
        });
      } catch (err) {
        logErrorsService.error(err.message, "Error render reCAPTCHA V2");
      }

      window.grecaptcha.execute();
      for (let i = 0; i < 120; i++) {
        response = window.grecaptcha.getResponse();
        if (response !== "" && response.length > 1) {
          logErrorsService.info("got reCAPTCHA V2 response. i=" + i);
          break;
        }
        await sleep(1000);
      }

      if (response.length > 1) {
        error.config.headers["X-ReCAPTCHAToken"] = response;
        error.config.headers["X-ReCAPTCHAToken_V"] = "V2";
        logErrorsService.info("reCAPTCHA V2 was successful, retrying the request.");
        return axios.request(error.config);
      } else {
        logErrorsService.warning("no response from reCAPTCHA V2");
      }
    }

    if (error.response?.status === 500) {
      store.dispatch(
        showSnackbar({
          variant: "failure",
          message: "Something went wrong. Please try again later."
        })
      );
    }

    if (error.response?.status === 401) {
      logErrorsService.info("http 401 URL: " + error?.request?.responseURL);
      localStorageSyncApi.removeJwtToken();
      sessionStorageSyncApi.removeJwtToken();
      if (!window.location.pathname.startsWith('/auth')) {
        window.location.replace("/");
      }
    }

    let errorDataObject = { ...error };
    if (errorDataObject?.config?.data) {
      try {
        errorDataObject.config.data = JSON.parse(errorDataObject.config.data);
        if (errorDataObject?.config?.data?.password) {
          errorDataObject.config.data.password = "******";
        }
      } catch (ex) { }
    }

    logErrorsService.error(axiosError.errorDescription(error), error);
    throw error;
  }
);

const apiService = {
  get(url, params) {
    return axios({
      url: urlHelpers.getAbsoluteURL(url),
      method: "get",
      headers: this.getAxiosConfig(),
      params
    });
  },
  async post(url, body, params) {
    let reCAPTCHAToken, actionName;
    if (url.indexOf("api/auth/") > -1) {
      actionName = url.replace(/\/?api\/auth\//, "");
      reCAPTCHAToken = await this.getCaptchaToken(actionName);
    } else if (url.indexOf("api/PayrollApproval/EmailRegister") > -1) {
      actionName = "PayrollApproval";
      reCAPTCHAToken = await this.getCaptchaToken(actionName);
    }
    return axios({
      url: urlHelpers.getAbsoluteURL(url),
      method: "post",
      data: body,
      headers: this.getAxiosConfig(reCAPTCHAToken, actionName),
      params: params
    });
  },
  put(url, body, params) {
    return axios({
      url: urlHelpers.getAbsoluteURL(url),
      method: "put",
      data: body,
      headers: this.getAxiosConfig(),
      params: params
    });
  },
  delete(url, body, params) {
    return axios({
      headers: this.getAxiosConfig(),
      method: "delete",
      data: body,
      url: urlHelpers.getAbsoluteURL(url),
      params: params
    });
  },
  patch(url, body, params) {
    return axios({
      url: urlHelpers.getAbsoluteURL(url),
      method: "patch",
      data: body,
      headers: this.getAxiosConfig(),
      params: params
    });
  },
  downloadFile: async function (url, fileName, errorCallback, useApiFilename) {
    try {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ url }));
        return;
      }
      const response = await axios({
        url: urlHelpers.getAbsoluteURL(url),
        method: "GET",
        responseType: "blob", // important
        headers: this.getAxiosConfig()
      });
      const apiFilename = response?.headers?.["x-filename"] ?? fileName;
      clickToDownloadFile(response, useApiFilename ? apiFilename : fileName);
    } catch (e) {
      axiosError.handleError(e, () => {
        if(errorCallback){
          errorCallback("Internal Server Error. Please try again later.");
        }
      });
      throw e;
    }
  },
  downloadFileViaPost: async function (url, body, fileName, errorCallback) {
    try {
      const response = await axios({
        url: urlHelpers.getAbsoluteURL(url),
        method: "POST",
        data: body,
        responseType: "blob", // important
        headers: this.getAxiosConfig()
      });
      clickToDownloadFile(response, fileName);
    } catch (e) {
      axiosError.handleError(e, () => {
        errorCallback("Internal Server Error. Please try again later.");
      });
      throw e;
    }
  },
  getAxiosConfig(reCAPTCHAToken, actionName) {
    const token = authService.getToken();
    const bfp = localStorageSyncApi.getOrSetBfp();
    const sfp = sessionStorageSyncApi.getOrSetSfp();
    return reCAPTCHAToken
      ? {
        Authorization: `Bearer ${token}`,
        "X-ReCAPTCHAToken": reCAPTCHAToken,
        "X-ReCAPTCHAToken_V": "V3",
        "X-ReCAPTCHAToken_Action": actionName,
        bfp,
        sfp
      }
      : {
        Authorization: `Bearer ${token}`,
        bfp,
        sfp
      };
  },
  getCaptchaToken(action) {
    return new Promise((res, rej) => {
      try {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute("6LcTCwAVAAAAADYn_QkUxpU0IR0rAdr1RYae1Nfb", { action: action })
            .then(function (token) {
              res(token);
            });
        });
      } catch (ex) {
        logErrorsService.error(`Error in getCaptchaToken - ${ex.message}`);
        res(`Error: ${ex}`);
      }
    });
  },
  getFile: async function (url, fileName, errorCallback) {
    try {
      const response = await axios({
        url: urlHelpers.getAbsoluteURL(url),
        method: "GET",
        responseType: "blob", // important
        headers: this.getAxiosConfig()
      });
      const fileUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      return fileUrl;
    } catch (e) {
      axiosError.handleError(e, () => {
        if (errorCallback) {
          errorCallback("Internal Server Error. Please try again later.");
        }
      });
      throw e;
    }
  }
};
export default apiService;

function clickToDownloadFile(response, fileName) {
  const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}
