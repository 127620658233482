import React, { useMemo, useRef, useState } from 'react'

import { Avatar, Backdrop, Box, Button, CircularProgress, Dialog, ThemeProvider, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { theme } from '../../../lib/theme'
import { getErrorMessage } from '../../../utils/error'
import { AlertTriangleIcon } from '../icons/alert-triangle'
import { CheckCircleIcon } from '../icons/check-circle'
import { InfoCircleIcon } from '../icons/info-circle'
import { AppContext, AppContextProps, MessageDialogOptions } from './app-context'
import { DialogTransition } from './dialog-transition'
import { SnackbarCloseAction } from './snackbar-close-action'

interface Props {
  children: React.ReactNode
}

export function AppProvider({ children }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const [messageDialogOptions, setMessageDialogOptions] = useState<MessageDialogOptions | null>(null)

  const showLoadingTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const value = useMemo<AppContextProps>(
    () => ({
      isLoading,
      showLoading: () => {
        if (showLoadingTimeoutRef.current) {
          clearTimeout(showLoadingTimeoutRef.current)
        }
        showLoadingTimeoutRef.current = setTimeout(() => setIsLoading(true), 1000)
      },
      hideLoading: () => {
        if (showLoadingTimeoutRef.current) {
          clearTimeout(showLoadingTimeoutRef.current)
          showLoadingTimeoutRef.current = null
        }
        setIsLoading(false)
      },
      showMessageDialog: (options: MessageDialogOptions) => {
        setMessageDialogOptions(options)
      },
      showErrorDialog: (error) => {
        setMessageDialogOptions({
          variant: 'error',
          title: 'An error occurred',
          message: getErrorMessage(error),
        })
      },
      showMessageToast: (options) => {
        enqueueSnackbar(options.message, {
          variant: options.variant,
          autoHideDuration: 4000,
          action: (id) => <SnackbarCloseAction snackbarId={id} />,
        })
      },
      showErrorToast: (error) => {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
          autoHideDuration: 4000,
          action: (id) => <SnackbarCloseAction snackbarId={id} />,
        })
      },
    }),
    [enqueueSnackbar, isLoading],
  )

  const isMessageDialogOpen = !!messageDialogOptions

  return (
    <>
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
      <ThemeProvider theme={theme}>
        <Backdrop open={isLoading} sx={{ zIndex: 2000 }}>
          <CircularProgress disableShrink />
        </Backdrop>
        <Dialog
          open={isMessageDialogOpen}
          keepMounted
          TransitionComponent={DialogTransition}
          onClose={() => setMessageDialogOptions(null)}
          sx={{
            '& .MuiDialog-paper': {
              borderWidth: '2px 0 0 0',
              borderTopColor: `${messageDialogOptions?.variant}.light`,
            },
          }}
        >
          {messageDialogOptions && (
            <Box display="flex" flexDirection="column" gap={2} p={2}>
              <Avatar sx={{ bgcolor: `${messageDialogOptions.variant}.main`, width: 44, height: 44 }}>
                {messageDialogOptions.variant === 'warning' && <AlertTriangleIcon sx={{ color: 'common.white' }} />}
                {messageDialogOptions.variant === 'success' && <CheckCircleIcon sx={{ color: 'common.white' }} />}
                {(messageDialogOptions.variant === 'info' || messageDialogOptions.variant === 'error') && (
                  <InfoCircleIcon sx={{ color: 'common.white' }} />
                )}
              </Avatar>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h6">{messageDialogOptions.title}</Typography>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
                  {messageDialogOptions.message}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button variant="outlined" color="inherit" size="small" onClick={() => setMessageDialogOptions(null)}>
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </Dialog>
      </ThemeProvider>
    </>
  )
}
