import { AxiosError } from "axios";

interface ValidationError {
  errorCode: string;
  errorMessage: string;
  propertyName: string;
}

export function getErrorMessage(error: unknown | Error | AxiosError) {
  let message = "";

  if (error instanceof AxiosError) {
    if (error.response) {
      if (typeof error.response.data === "string") {
        message = error.response.data;
      } else if (error.response.data?.title) {
        message = error.response.data.title;
      } else if (error.response.data?.message) {
        message = error.response.data.message;
      } else if (error.response.data.errors) {
        const errors = error.response.data.errors as ValidationError[];
        message = errors.map((e) => e.errorMessage).join("\n");
      }
    } else {
      message = error.message;
    }
  } else if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  }

  return message || "An error occurred. Please try again.";
}
