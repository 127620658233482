import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorBoundary from "../ErrorBoundary";
import { getToken } from "../../api/zendeskApi";
import { selectProfile } from "../../redux/ducks/appDuck";
import { authService, logErrorsService } from "../../core";

const ZENDESK_KEY = "214c9204-4b2d-4279-976a-8e0039539723";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const setting = {
  webWidget: {
    zIndex: 1200,
    color: {
      theme: "#2c11a3",
      launcherText: "#ffffff",
      header: "#1cf2e2",
    },
    helpCenter: {
      searchPlaceholder: {
        "*": "Search our Help Center",
      },
      title: {
        "en-US": "Search for help",
      },
    },
    chat: {
      suppress: false,
      notifications: {
        mobile: {
          disable: true,
        },
      },
      title: {
        "*": "Chat with us!",
      },
    },
  },
};

const WidgetZendesk = () => {
  const [loaded, setLoaded] = React.useState(false);
  const profile = useSelector(selectProfile);
  const currentCompanyId = profile?.conum;
  const companyName = profile?.companyName;
  const conumRef = useRef(currentCompanyId);
  const coNameRef = useRef(companyName);
  const loadedRef = useRef(loaded);
  const { pathname } = useLocation();

  const setChatCoInfo = async () => {
    if (conumRef.current && loadedRef.current) {
      window?.zE("messenger:set", "conversationTags", [conumRef.current.toString(), coNameRef.current]);
      window?.zE("messenger:set", "conversationFields", [{ id: "360011866433", value: coNameRef.current }]);
      window?.zE("messenger:set", "conversationFields", [{ id: "360011866093", value: conumRef.current.toString() }]);
    }
  };

  const handleScriptLoaded = () => {
    setLoaded(true);
    window?.zE("messenger:on", "open", function () {
      setChatCoInfo();
    });
  };

  React.useEffect(() => {
    if (loaded) return;
    const script = document.createElement("script");
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    script.defer = true;
    script.id = "ze-snippet";
    script.addEventListener("load", handleScriptLoaded);
    document.body.appendChild(script);
    window.zESettings = setting;
    return () => {
      document.body.removeChild(script);
    };
  }, [loaded, currentCompanyId]);

  React.useEffect(() => {
    conumRef.current = currentCompanyId;
    coNameRef.current = companyName;
    loadedRef.current = loaded;
    setChatCoInfo();
    if (loaded && currentCompanyId && authService.isCompanyAuthenticated()) {
      window?.zE("messenger", "loginUser", async function (callback) {
        const token = await getToken();
        callback(token);
        setChatCoInfo();
      });
    } else if (loaded && !currentCompanyId) {
      window?.zE("messenger", "logoutUser");
    }
  }, [currentCompanyId, companyName, loaded]);

  React.useEffect(() => {
    if (!loaded) return;
    if (pathname === "/swipe-clock") {
      window?.zE("messenger", "hide");
    } else if (pathname.startsWith("/ess/")) {
      window?.zE("messenger", "hide");
    } else if (pathname.startsWith("/ess/setup")) {
      window?.zE("messenger", "hide");
    } else if (pathname.match(/^\/payrolls\/\d+$/)) {
      window?.zE("messenger", "hide");
    } else {
      window?.zE("messenger", "show");
    }
  }, [pathname, loaded]);

  React.useEffect(() => {
    const setZIndex = async () => {
      await sleep(500);
      window?.zE("messenger:set", "zIndex", 1200);
    };
    if (loaded) setZIndex();
  }, [loaded]);

  return null;
};

const WrappedWidgetZendesk = () => {
  return (
    <ErrorBoundary noFallback>
      <WidgetZendesk />
    </ErrorBoundary>
  );
};

export default WrappedWidgetZendesk;
