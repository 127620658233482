import { IconButton } from '@mui/material'
import { SnackbarKey, useSnackbar } from 'notistack'

import { XCloseIcon } from '../icons/x-close'

interface Props {
  snackbarId: SnackbarKey
}

export function SnackbarCloseAction({ snackbarId }: Props) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <XCloseIcon sx={{ color: 'common.white' }} />
    </IconButton>
  )
}
